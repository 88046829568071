import React from 'react';
import { Link } from '@aws-amplify/ui-react';
import {
  Link as ReactRouterLink,
} from 'react-router-dom';

export class SideNav extends React.Component {
  render() {
    return (
      <div id="sideNavContainer" className="sideNav">
        <ul>
          <li className="sideNavListItem">
            <Link as={ReactRouterLink} to="/home">Home</Link>
          </li>
          <li className="sideNavListItem">
            <Link as={ReactRouterLink} to="/game">Game</Link>
          </li>
          <li className="sideNavListItem">
            <Link as={ReactRouterLink} to="/list">List</Link>
          </li>
        </ul>
      </div>
    );
  }

}

export default SideNav;