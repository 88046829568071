import React from 'react';

export class SideNavToggleBtn extends React.Component {

  render() {

    var isOpen = 0; // Left Navigation Toggle switch.

    function LeftNavigationToggle() {
      var element = document.getElementsByClassName("btnLeftNavigationToggle");
      element[0].classList.toggle("change");

      if (isOpen) {
        closeNav();
        isOpen = 0;
      } else {
        openNav();
        isOpen = 1;
      }
    }

    function openNav() {
      document.getElementById("sideNavContainer").style.width = "250px";
      document.getElementById("btnLeftNavContainer").style.marginLeft = "250px";

      // black out btnLeftNavContainer div
      //document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
    }

    function closeNav() {
      document.getElementById("sideNavContainer").style.width = "0";
      document.getElementById("btnLeftNavContainer").style.marginLeft = "0";

      // Undo black out btnLeftNavContainer div
      //document.body.style.backgroundColor = "white";
    }

    return (
      <div id="btnLeftNavContainer">
        <div className="btnLeftNavigationToggle" onClick={LeftNavigationToggle}>
          <div className="btnLeftNavBar1"></div>
          <div className="btnLeftNavBar2"></div>
          <div className="btnLeftNavBar3"></div>
        </div>
      </div>
    );
  }

}

export default SideNavToggleBtn;