/* src/App.js */
import React from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Game from './Game';
import List from './List';
import SideNavToggleBtn from './SideNavToggleBtn';
import '@aws-amplify/ui-react/styles.css';
import { SideNav } from './SideNav';

class App extends React.Component {

  render() {
    return (
      <div style={styles.appWrapper}>
        <SideNavToggleBtn />

        <Router>
          <SideNav />

          <div style={styles.mainContent}>
            <Routes>
              <Route path="/home">
                <Route exact path='/home' element={""} />
              </Route>
              <Route path="/game">
                <Route exact path='/game' element={<Game />} />
              </Route>
              <Route path="/list">
                <Route exact path='/list' element={<List user={this.props.user} />} />
              </Route>
            </Routes>

            <h1>Hello {this.props.user.username}</h1>
            <button style={styles.button} onClick={this.props.signOut}>Sign out</button>
          </div>

        </Router>
      </div>
    );
  }
}

// TODO: Throw into app.css
const styles = {
  appWrapper: { width: 'auto', margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
  mainContent: { width: 400, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
  todo: { marginBottom: 15 },
  input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  todoName: { fontSize: 20, fontWeight: 'bold' },
  todoDescription: { marginBottom: 0 },
  button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' },
  button2: { fontSize: 30, cursor: 'pointer', width: 150 }
}

export default withAuthenticator(App);